import * as React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: 'none',
      borderBottom: '1px solid #F78D1F'
    }
  }));

export default function FormsPage(){

    const classes = useStyles();

    return (
        <Layout>
        <SEO title="Forms" />
        <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
            <Grid item style={{padding: '2rem'}} xs={12}>
                <Typography variant="h3" style={{color: '#243E83'}}>Required Participation Forms</Typography>
            </Grid>
            <Grid item style={{padding: '1rem', width: '90%', flexFlow: 'column'}} container direction="column" spacing={4} xs={12}>
                <Grid item>
                    <Accordion className={classes.root}>  
                        <AccordionSummary
                            expandIcon={<AddCircleIcon style={{color: "#243E83"}}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Grid container direction="column" alignItems="baseline" style={{overflow: 'hidden}'}}>
                                <Typography variant="h4">Covid 19 Questionaire</Typography>
                                <a href="https://forms.gle/vHUJPCWhfCkdTLwQ8" target="_blank"
                                    style={{maxWidth: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>https://forms.gle/vHUJPCWhfCkdTLwQ8</a>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfGyVIbK796RZiQOUSauVj4Mr1YLDrF3sh5WhYv9IFs6Ky8Pw/viewform?embedded=true" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion className={classes.root}>  
                        <AccordionSummary
                            expandIcon={<AddCircleIcon style={{color: "#243E83"}}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Grid container direction="column" alignItems="baseline" style={{overflow: 'hidden'}}>
                                <Typography variant="h4">Assumption of Risk and Waiver</Typography>
                                <a href="https://drive.google.com/file/d/1Rvzrzve4QVjQultV9iCdNXcr9yVBcHPn/view?usp=sharing" target="_blank"
                                    style={{maxWidth: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>https://drive.google.com/file/d/1Rvzrzve4QVjQultV9iCdNXcr9yVBcHPn/view?usp=sharing</a>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <iframe src="https://drive.google.com/file/d/1Rvzrzve4QVjQultV9iCdNXcr9yVBcHPn/preview" width="100%" height="500" style={{minWidth: '250px'}} frameborder="0" marginheight="0" marginwidth="0">Loading</iframe>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion className={classes.root}>  
                        <AccordionSummary
                            expandIcon={<AddCircleIcon style={{color: "#243E83"}}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Grid container direction="column" alignItems="baseline" style={{overflow: 'hidden'}}>
                                <Typography variant="h4">USAV Player Medical Release</Typography>
                                <a href="https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release_w_ClubTeam_WITHOUT_Notary_fillable.pdf?fbclid=IwAR3wNzM8cUpczCLTvkaxGbg2RpTUEtpAmFE_ai25UqGstfNbTwsIXUjsJrg#_ga=2.30635641.773926524.1621193294-725735853.1615691333" target="_blank"
                                    style={{maxWidth: "100px", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                    https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release.pdf
                                </a>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <iframe src="https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release_w_ClubTeam_WITHOUT_Notary_fillable.pdf?fbclid=IwAR3wNzM8cUpczCLTvkaxGbg2RpTUEtpAmFE_ai25UqGstfNbTwsIXUjsJrg#_ga=2.30635641.773926524.1621193294-725735853.1615691333" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading</iframe>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
        </Layout>
    )
}